<template>
  <div class="panel-group-container">



            <div v-if="setbrandid === 'SV1'" class="panel-group">
        

            <div class="panel-row panel-row-3"><div class="panel-inner"><div class="panel no-image" data-v-d32d57f8="" data-v-093122d0=""><div class="panel-data" data-v-d32d57f8=""><h3 data-v-d32d57f8=""></h3><p data-v-d32d57f8=""><!-- {{ panel_data.brief_description }} --></p><!--v-if--></div><div class="panel-image" data-v-d32d57f8=""><img style="width: 100%; max-height: 100%" src="https://res.cloudinary.com/rezcomm2018/image/upload/v1717682968/brands/SV1/homepage/summitshuttle_wide.jpg" data-v-d32d57f8=""></div></div></div></div>
            </div>


    <div v-if="setbrandid === 'LDY'" class="panel-group">
      <div class="panel-row panel-row-3">
        <div class="panel-inner">
          <div
            class="panel image-right"
          >
            <div class="panel-data" >
              <h3>Secure parking</h3>
              <p> 
                <span>
                The car park will be locked and secured during periods when the airport is closed.
                Airport opening times (please note, these times are subject to change):</span>
                <table>
                    <tbody><tr>
                        <td>
                        Monday - Friday
                        </td>
                        <td>
                        <b>&nbsp;&nbsp;&nbsp;04:30 - 22:00</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Saturday
                        </td>
                        <td>
                        <b>&nbsp;&nbsp;&nbsp;04:30 - 16:00</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Sunday
                        </td>
                        <td>
                        <b>&nbsp;&nbsp;&nbsp;07:00 - 22:00</b>
                        </td>
                    </tr>
                </tbody></table>
                <br>
                <span style="margin-top: 15px;">If you require out of hours access to retrieve your vehicle (see airport opening times), 
                please contact the Airport Customer Services on: <a class="BF-marketing-link" target="_blank" href="tel:028 7181 3302">028 7181 3302</a> during normal operating hours,
                in advance where possible, and we will plan to accommodate your request.</span>
            </p>
            <br><br>
            <!-- <h3>Booking Updates & Cancellations</h3>
            <p> 
            <span>
              Please ensure all details provided within your booking are correct at the time of booking. Any amendments to your booking will require the cancellation of the booking and a new booking will have to be placed - a new booking can be made on this website or directly with our Parking team at City of Derry Airport: <a class="BF-marketing-link" target="_blank" href="tel:028 7181 3302">parking@cityofderryairport.com.</a>
              </span>
            </p> -->
            <br><br>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="announcement-banner" v-if="setbrandid === 'PIT'">
      Please be advised that Parking Lot 1 is currently closed, or a snowstorm has caused a blockage on certain roads within the site. We apologize for any inconvenience this may cause.
    </div>
    <div v-if="setbrandid === 'PIT'" class="panel-group-pit">
      <div class="inner-pannel">
        <iframe width="560" height="315" style="width: 100%; height: 400px" src="https://www.youtube.com/embed/xJozH8YBIb4?si=01cfY_z9yxKky1c4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <img width="560" height="auto" style="width: 100%; height: auto" src="https://res.cloudinary.com/rezcomm2018/image/upload/v1728399090/brands/PIT/REZ_ADA_Presentation.png"/>
      </div>
      <div>
        <img width="560" height="auto" style="width: 100%; height: auto" src="https://res.cloudinary.com/rezcomm2018/image/upload/v1727692731/brands/PIT/panels/1727692731_PIT-Parking-Lots-Map.webp/a44v6zmdz6jbht1ukuvk.webp"/>
      </div>
    </div>

    <div class="panel-group" v-if="setbrandid !== 'SV1' && setbrandid !== 'LDY' && setbrandid !== 'PIT'">
      <div
        v-for="(panel_row, i) in panels_group"
        :key="panel_row.row"
        :class="`panel-row panel-row-${i}`"
      >
        <div class="panel-inner">
          <template v-for="panel in panel_row.columns" :key="panel.panelid">
            <Panel
              :panel_data="panel"
              :lang="lang.toUpperCase()"
              :translations="translations"
              :brandSlug="setbrandid"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from "./Panel.vue";
export default {
  components: {
    Panel,
  },
  name: "PanelsGroup",
  props: {
    panels_group: undefined,
    lang: undefined,
    translations: undefined,
    setbrandid: undefined,
  },
};
</script>

<style scoped lang="scss">
.panel-group-container {
  background-color: var(--panel-background-color);

  @media screen and (min-width: 768px) {
    margin-top: 3rem;
    padding: 3rem 0;
  }

  @media screen and (max-width: 1260px) {
    padding: 0 1rem;
    padding: 1rem 0;
  }

  .panel {
    &-group {
      position: relative;
      // max-width: 1260px;
      margin: 0 auto;
      // padding: 3rem 0;
    }

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;

      padding: 0 1rem;

      // align-items: center;

      // @media screen and (max-width: 566.99px) {
      //   align-items: center;
      // }

      // @media screen and (min-width: 567px) {
      //   flex-direction: row;
      //   flex-wrap: wrap;
      // }

      // @media screen and (min-width: 960px) {
      //   flex-direction: row;
      //   flex-wrap: nowrap;
      // }
    }
  }

  .panel-group {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 320px;
  }

  .panel-inner {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 566.99px) {
      align-items: center;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 567px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 960px) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}
</style>

<style lang="scss">
// NWI Brand mods
.NWI {
  .panel-group-container {
    padding: 0;
    background: rgb(245, 245, 245);
    .panel-row-1 {
      padding: 3rem 0;
      @media screen and (max-width: 1320px) {
        padding: 3rem 1rem;
      }
      .panel.image-left {
        justify-content: flex-end;
      }
      .panel-image img {
        max-height: 220px;
      }
      .panel.image-right {
        max-width: 100%;
      }
    }
  }
}

// IP1 Brand mods
.IP1 {
  .panel-group-container {
    padding: 0;
    background: #fff;
    .panel-row-1 {
      background: rgb(245, 245, 245);
      padding: 3rem;
      .panel.image-left {
        justify-content: flex-end;
      }
      .panel-image img {
        max-height: 220px;
      }
    }
    .panel-row-2,
    .panel-row-3 {
      text-align: center;
      .panel.image-top {
        box-shadow: 0px 0px 0px #00000000;
      }
      .panel-image img {
        width: auto;
        max-height: 150px;
      }
      .panel-data {
        padding: 1rem;
      }
    }
    .panel-row-2 {
      padding-top: 3rem;
    }
    .panel-row-3 {
      padding-bottom: 3rem;
    }
  }
}

.PIT {
  .panel-group-container {
    padding: 0;
    background: #fff;
    
  }
}

.panel-group-pit {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 1280px;
  gap: 2rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 1260px) {
    padding: 0 1rem;
    padding: 1rem 0;
    max-width: calc(100% - 2rem);
    margin-top: 10px;
  }
}

.announcement-banner {
  width: 100%;
  margin-top: -25px;
  background-color: #f5c6cb;
  color: #721c24;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 1260px) {
    margin-top: 10px;
  }
}

.inner-pannel{
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: flex-start;
  align-items: center;
}

@import "@/assets/sass/override_brand.scss";
</style>
